// index.js
import React from "react";
import Typography from "../../../components/Typography";
import ScheduleForm from "../../FormsThird/Consultation-Classic";
import { Container, Content, FormContainer, FormHeading, IconsWrapper, IconItem, ImageBackground } from "./styled";
import authority from "../EEAT/_authority.json";
import Image from "next/image";

export default function GetStarted({ formId }) {
  const handleFormSuccess = (response) => {
    console.log("GetStarted form submitted successfully", response);
    // If any additional actions needed after form success, add them here
  };

  return (
    <Container>
      <ImageBackground />
      <Content>
        <div>
          <FormContainer>
            <FormHeading>
              Complimentary Design Consultation & Estimate
            </FormHeading>
            
            <ScheduleForm 
              formId={formId || "get-started-home-page"} 
              submitButton="GET STARTED" 
              variant="small" 
              darkLabels={false}
              onSuccess={handleFormSuccess}
            />
            
            <IconsWrapper>
              {authority.map((item) => (
                <IconItem key={item.id}>
                  <Image
                    src={item.icon}
                    alt={item.title}
                    width={40}
                    height={40}
                    quality={70}
                  />
                  <span className="icon-title">{item.title}</span>
                </IconItem>
              ))}
            </IconsWrapper>
          </FormContainer>
        </div>
      </Content>
    </Container>
  );
}
